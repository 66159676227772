import * as React from 'react'
import { motion } from 'framer-motion'
import { navigate } from '@reach/router'
import { styled } from '../design-system/styled'

import '../assets/fonts/FranklinGothic-Book.css'
import '../assets/fonts/SpaceMono.css'
import '../../ticket-site.css'

interface TicketItem {
  ticketType: string
  description: string | React.FC
  button?: boolean
}

interface FAQType {
  question: string
  answer: React.FC
  idx?: number
}

const TicketSite = () => (
  <>
    <Petal
      src="/petal-left.png"
      initial={{ opacity: 0, y: -50, rotate: 10, scale: 0.8 }}
      animate={{ opacity: 1, y: 0, rotate: 0 }}
      transition={{ duration: 3.5, delay: 1.5 }}
    />
    <Petal
      src="/petal-right.png"
      initial={{ opacity: 0, y: -50, scale: 0.5, rotate: 5 }}
      animate={{ opacity: 1, y: 0, rotate: 0 }}
      transition={{ duration: 5 }}
    />
    <OuterContainer>
      <InnerContainer>
        <HalfContainer>
          <H1>The World Transformed 2021</H1>
          <H2>Brighton // 25th - 28th September</H2>
          <Copy>
            After 18 months of isolation, our movement desperately needs to come
            together in person to rebuild power from the ground up.
          </Copy>
          <ButtonsContainer>
            <AnchorButton onClick={() => navigate('#tickets')}>
              Tickets &gt;
            </AnchorButton>
            <AnchorButton onClick={() => navigate('#faqs')}>
              FAQs &gt;
            </AnchorButton>
            <AnchorButton onClick={() => navigate('#donate')}>
              Donate &gt;
            </AnchorButton>
            <AnchorButton
              onClick={() => navigate('/twt21/calendar')}
              bgColor="#ff5e80"
            >
              Programme &gt;
            </AnchorButton>
          </ButtonsContainer>
        </HalfContainer>
        <HalfContainer id="tickets">
          <H2>Tickets</H2>
          <Copy>
            The cost of running this year&apos;s TWT will cost us £100 / head.
            Luckily, thanks to the generosity of our partner organisations,
            we&apos;re able to offer subsidised tickets to our attendees.
          </Copy>
          <Tickets
            tickets={[
              {
                ticketType: 'Pay it forward (£100)',
                description:
                  'a ticket for you, plus a free one for someone that needs it. To say thanks, we’ll also throw in a lifetime digital subscription to Red Pepper too.',
                button: true,
              },
              {
                ticketType: 'Standard (£50)',
                description:
                  'for those that want to help TWT break even at this year’s festival.',
                button: true,
              },
              {
                ticketType: 'Low wage (£35)',
                description:
                  'for those that want to support TWT, but can only afford a lower price this year.',
                button: true,
              },
              {
                ticketType: 'Online content pass',
                description: OnlinePass
              },
              {
                ticketType: 'Free',
                description: FreeTickets,
              },
            ]}
          />
        </HalfContainer>
      </InnerContainer>
      <FAQsContainer id="faqs">
        <PaddedH2>FAQs</PaddedH2>
        <FAQs
          faqs={[
            {
              question: 'Where is the festival?',
              answer: Answer0,
            },
            {
              question: 'What is the TWT21 COVID policy?',
              answer: Answer1,
            },
            {
              question: 'What happens if the festival is cancelled?',
              answer: Answer2,
            },
            { question: 'Where can I stay?', answer: Answer3 },
            {
              question:
                'What accessibility provisions will be at the festival?',
              answer: Answer4,
            },
            {
              question: "What if I can't make it to the festival?",
              answer: Answer5,
            },
            {
              question:
                'Will there be any childcare available at the festival?',
              answer: Answer6,
            },
          ]}
        />
      </FAQsContainer>
      <PaddedH2 id="donate">Donate</PaddedH2>
      <DonateButton />
    </OuterContainer>
  </>
)

// Functional Components
const DonateButton = () => (
  <SelfCentred>
    <StyledDonate
      target="_blank"
      rel="noopener noreferrer"
      href="https://actionnetwork.org/fundraising/donate-to-twt21/"
    >
      Click here to make an online donation ↗
    </StyledDonate>
  </SelfCentred>
)

const FAQs = ({ faqs }: { faqs: FAQType[] }) => (
  <>
    {faqs.map((faq, idx) => (
      <FAQ key={`faq-${idx}`} {...faq} idx={idx} />
    ))}
  </>
)

const FAQ = ({ question, answer: AnswerComponent }: FAQType) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <FAQContainer>
      <FAQButton onClick={() => setIsOpen(!isOpen)}>
        <span>•</span>
        <Question>{question}</Question>
        <ArrowContainer isOpen={isOpen}>&gt;</ArrowContainer>
      </FAQButton>
      {isOpen && <AnswerComponent />}
    </FAQContainer>
  )
}

const Tickets = ({ tickets }: { tickets: TicketItem[] }) => {
  const [activeIdx, setActiveIdx] = React.useState(0)
  const activeTicket = tickets[activeIdx]
  const ActiveDescription = activeTicket.description

  return (
    <TicketContainer>
      <TicketLeft>
        {tickets.map(({ ticketType }, index) => (
          <TicketButton
            isActive={index === activeIdx}
            onClick={() => setActiveIdx(index)}
            key={`ticketType-${ticketType}`}
          >
            {ticketType}
          </TicketButton>
        ))}
      </TicketLeft>
      <TicketRight>
        <Copy>
          {typeof ActiveDescription === 'string' ? (
            ActiveDescription
          ) : (
            <ActiveDescription />
          )}
        </Copy>
        {activeTicket.button && (
          <BuyLink
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.tickettailor.com/events/theworldtransformed/554783"
          >
            Buy &gt;
          </BuyLink>
        )}
      </TicketRight>
    </TicketContainer>
  )
}

// Functional Components: Hardcoded Content
const Answer0 = () => (
  <AnswerContainer>
    <Copy>
      TWT21 will take place in multiple venues across Brighton. We'll be back at the Old Steine Site, which be the social hub of this year’s festival - as well as home to our opening party, panels, debates, rallies & more. And yes, the Socialism sign is back.
    </Copy>
    <Copy>
      Almost all of our festival sessions will take place at the following venues:
    </Copy>
    <Copy>
      <ul>
        <li>Old Steine Gardens - BN1 1NH</li>
        <li>Community Base - 113 Queens Road, BN1 3XG</li>
        <li>Brighthelm Centre - North Rd, Brighton BN1 1YD</li>
        <li>Friend's Meeting House - Ship St, BN1 1AF</li>
        <li>Latest Bar - 14-17 Manchester St, BN2 1TF</li>
      </ul>
    </Copy>
  </AnswerContainer>
)

const Answer1 = () => (
  <AnswerContainer>
    <Copy>
      The team at TWT have been closely monitoring the COVID situation and assessing the measures that other large scale events are taking. Considering our logistical capacity as well as what measures we feel are the most effective to prevent the spread of COVID at this year’s event, we have come up with the following COVID policies for TWT21. The main goal for us is to ensure everyone has total clarity on what to expect.
    </Copy>
    <Copy>
      You can read our <a 
        href="https://docs.google.com/document/d/1RsmcW21rvz54u1EVocBpfevsOBbIIlWsxKrvdxpxUek/edit"  
        target="_blank"
        rel="noopener noreferrer">full COVID policy here</a>.
    </Copy>
  </AnswerContainer>
)

const Answer2 = () => (
  <AnswerContainer>
    <Copy>
      if, in the worst case scenario we had to cancel the festival - and because
      our government has refused to make Covid-19 specific cancellation
      insurance available for live events - we’d be unable to claim back the
      money we have already spent on it.
    </Copy>
    <Copy>
      That’s why we’re asking everyone who is buying a ticket to TWT21 to agree
      to terms that mean only half of their ticket will be refundable in the
      event of a COVID-19 cancellation, and the other half is a non-refundable
      pledge to help TWT overcome the financial risk of running a festival this
      year, and remain an institution of the British left for years to come.
      This non-refundable pledge is essential if we are to continue planning
      from this point on.
    </Copy>
    <Copy>
      We understand this is a lot to ask, and thank you all for your
      understanding in this difficult time. We promise to be as transparent as
      possible with you as planning continues.
    </Copy>
  </AnswerContainer>
)

const Answer3 = () => (
  <AnswerContainer>
    <AnswerSubsection>
      <AnswerSubheading>Hotels & Hostels</AnswerSubheading>
      <Copy>
        There are a number of youth hostels that offer affordable accommodation
        in Brighton - you can check in sites such as Hostelworld.com.
      </Copy>
      <Copy>
        For those who don’t mind commuting into Brighton, here’s a list of
        nearby towns where you may be able to find affordable accommodation:
        Shoreham-by-Sea, Saltdean/Rottingdean, Lewes (good train connection),
        Burgess Hill, Hassocks, Worthing (good train connection), and Falmer
        (good train connection).
      </Copy>
    </AnswerSubsection>
    <AnswerSubsection>
      <AnswerSubheading>Campsites</AnswerSubheading>
      <Copy>
        Brighton has a number of campsites that have good facilities and are at
        easy reach from central Brighton (most of them easily accessible by
        car). One that it’s particularly easy to get to is the Brighton Caravan
        Club Site, situated less than 30 minutes away by public transport from
        our main venue in The Old Steine Gardens, or 45 minutes if you chose to
        take a morning/evening stroll.
      </Copy>
    </AnswerSubsection>
    <AnswerSubsection>
      <AnswerSubheading>Air BnB and Couchsurfing</AnswerSubheading>
      <Copy>
        There are plenty of lovely Air BnBs in Brighton and nearby towns (listed
        above). We recommend you take a look to find out if there are any
        available for the size you’re after, and booking ASAP if you find
        something suitable.
      </Copy>
      <Copy>
        We have also set up a{' '}
        <a
          href="https://www.facebook.com/groups/1174631379711317"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook group
        </a>{' '}
        to match up attendees with one another for the purpose of sharing the
        cost of an Air BnBs, and finding spare rooms from Brighton based TWT
        attendees.
      </Copy>
    </AnswerSubsection>
    <AnswerSubsection>
      <AnswerSubheading>Community halls</AnswerSubheading>
      <Copy>
      This year The World Transformed will be offering subsidised accommodation to those wishing to attend the festival on a budget. This accommodation will be in Community Base's conference hall, just a short walk from all TWT21 Venues. You can find more info and book yours <a href="https://www.tickettailor.com/events/theworldtransformed/579599">here</a>.
      </Copy>
    </AnswerSubsection>
  </AnswerContainer>
)

const Answer4 = () => (
  <AnswerContainer>
    <Copy>
      We are committed to providing an event that’s accessible as possible. This includes: step free venues, a number of BSL interpreted events (TBA), free festival tickets for assistants, and accessibility passes for the duration of the festival to minimise times spent queueing, and a priority seating space at the front of sessions.
    </Copy>
    <Copy>
      We are working closely Disabled People Against Cuts to make this year’s festival accessible and enjoyable for all, and are being guided by the social model of disability to make this possible. You can read our full accessibility statement <a href="https://docs.google.com/document/d/1NNr-lq0XCbdLtnw9jj3IIRy3EnJXXQsu6FAp_cfyK0w/edit">here</a>.
    </Copy>
    <Copy>
      If you have any questions, please get in touch at{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:logistics@theworldtransformed.org"
      >
        logistics@theworldtransformed.org
      </a>
    </Copy>
  </AnswerContainer>
)

const Answer5 = () => (
  <AnswerContainer>
    <Copy>
      For those that can’t make it down to Brighton for the duration of the
      festival, not to worry - you can still participate in TWT21! As the
      programme takes shape, we’ll be working to ensure there’s a great offering
      of talks and discussions online too. This will include:
    </Copy>
    <Copy>
      <ul>
        <li>Live online only events</li>
        <li>Interviews and talks direct from the festival</li>
        <li>Recorded sessions from the festival</li>
      </ul>
    </Copy>
    <Copy>
      Stay tuned for more information on this, including how to get your hands
      on an online content pass for this year.
    </Copy>
    <Copy>
      You can also make a donation towards the running costs of this year’s
      event{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://actionnetwork.org/fundraising/donate-to-twt21/"
      >
        here.
      </a>
    </Copy>
  </AnswerContainer>
)

const Answer6 = () => (
  <AnswerContainer>
    <Copy>
      TWT will be running a creche for attendees&apos; children free of charge
      for the duration of the festival. It will be in{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://goo.gl/maps/GrDug9asxfxTj9649"
      >
        Bhasvic (205 Dyke Rd, East Sussex, Hove BN3 6EG)
      </a>{' '}
      from 10:30am - 5:30pm on Saturday, and{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://goo.gl/maps/KCKXgZQ4XAUZEnog9"
      >
        Phoenix Centre (2 Phoenix Pl, Brighton BN2 9ND)
      </a>{' '}
      Sunday - Tuesday.
    </Copy>
  </AnswerContainer>
)

const FreeTickets = () => (
  <>
    As always, this year we&apos;ll be offering free festival tickets to those
    that need them. If that&apos;s you, please email{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="mailto:info@theworldtransformed.org"
    >
      info@theworldtransformed.org
    </a>{' '}
    with the subject line &apos;Free ticket&apos; and we&apos;ll get that sorted
    for you as soon as we can.
  </>
)

const OnlinePass = () => (
  <>
    <Copy>We’ll be accepting donations for those that can’t make this year’s event physically, but want to tune in from home.</Copy>
    <Copy>You can look at events that <a href="/twt21/calendar?formation=online">are online by using the calendar</a>.</Copy>
  </>
)

// Styled Containers
const OuterContainer = styled.main`
  color: #1e0096;
  padding-top: 225px;
  padding-bottom: 70px;

  & * {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    padding-top: 70px;
  }
`
const InnerContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const HalfContainer = styled.div`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const FAQContainer = styled.section`
  &:nth-of-type(5n) {
    background: #9cd3ea;
  }

  &:nth-of-type(5n-1) {
    background: #ffebb0;
  }

  &:nth-of-type(5n-2) {
    background: #ff5e82;
  }

  &:nth-of-type(5n-3) {
    background: #ffc8ff;
  }

  &:nth-of-type(5n-4) {
    background: #ffcc98;
  }
`

const AnswerContainer = styled.div`
  column-count: 2;
  column-gap: 60px;

  color: black;
  padding: 52px 40px;

  @media screen and (max-width: 768px) {
    padding: 0px 40px 40px 40px;
    column-count: 1;
  }
`

const ButtonsContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 82.5px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
  }
`

const SelfCentred = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TicketContainer = styled.div`
  padding-top: 40px;
  display: flex;
  width: 100%;
  padding-bottom: 82.5px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 50px;
  }
`

const TicketLeft = styled.div`
  padding-right: 30px;

  @media screen and (max-width: 768px) {
    padding-right: 0px;
  }
`

const TicketRight = styled.div`
  padding-top: 12px;
  width: 50%;
  font-family: FranklinGothic;
  color: black;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const FAQsContainer = styled.div`
  width: 100%;
  padding-bottom: 82.5px;
`

const AnswerSubsection = styled.div`
  padding-left: 40px;
  margin-bottom: 19.2px;

  @media screen and (max-width: 768px) {
    padding-left: 0px;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
`

// Styled Text
const H1 = styled.h1`
  font-family: 'AA Gothic Bold', SpaceMono, sans-serif;
  color: #1e0096;
  font-weight: bold;
  font-size: 63px;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
`

const H2 = styled.h2`
  font-family: 'AA Gothic Bold', SpaceMono, sans-serif;
  color: #1e0096;
  font-weight: bold;
  font-size: 28px;
  padding-bottom: 10px;
  margin: 0;
`

const Copy = styled.p`
  font-family: FranklinGothic;
  font-size: 16px;
  line-height: 19.2px;

  margin-bottom: 19.2px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`

const AnswerSubheading = styled.h3`
  font-family: 'AA Gothic Bold', SpaceMono, sans-serif;
  font-size: 24px;
  color: #1e0096;
  font-weight: bold;
`

const PaddedH2 = styled(H2)`
  padding-left: 40px;
`

const Question = styled.span`
  width: 100%;
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 768px) {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
  }
`

// Styled Buttons and Links
const StyledDonate = styled.a`
  font-family: 'AA Gothic Bold', SpaceMono, sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #1e0096;
  text-decoration: none;
  display: inline-block;
  border-radius: 3000px;
  padding: 31px 106px;
  background-color: #00ff80;
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-left: 40px;
  margin-right: 40px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    padding: 15px 52px;
  }
`

const AnchorButton = styled.button`
  display: inline-block;
  cursor: pointer;
  font-family: FranklinGothic;
  text-decoration: none;
  border-radius: 500px;
  padding: 9px 23px;
  background-color: ${({ bgColor }: { bgColor?: string }) =>
    bgColor ? bgColor : '#00ff80'};
  color: #1e0096;
  font-size: 16px;
  margin-right: 13px;
  border: none;

  &:last-of-type {
    margin-right: 0px;
  }

  @media screen and (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 13px;
  }
`

const FAQButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: none;
  font-family: 'AA Gothic Bold', SpaceMono, sans-serif;
  font-size: 24px;
  color: #1e0096;
  font-weight: bold;
  padding: 40px;
`

const BuyLink = styled(AnchorButton)`
  background-color: #ff5e80;
  margin-top: 16px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`

const TicketButton = styled.button`
  border: none;
  background: none;
  display: block;
  cursor: pointer;
  font-family: FranklinGothic;
  color: #1e0096;
  font-size: 20px;
  margin-bottom: 8px;
  opacity: ${({ isActive }: { isActive: boolean }) => (isActive ? '1' : '0.5')};

  &:last-of-type {
    margin-bottom: 0px;
  }

  @media screen and (max-width: 768px) {
    margin: 0px;
    text-align: center;
    width: 100%;
  }
`

// Styled Images
const ArrowContainer = styled.span`
  transition 0.2s;
  transform: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
`

const Petal = styled(motion.img)`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  top: -20vw;
  left: -35vw;
  width: 100%;
  height: auto;
  overflow: hidden;

  &:last-of-type {
    left: inherit;
    top: -40vw;
    right: -30vw;
  }
`

export default TicketSite
